'use client';

import { PageError } from '@/components/PageError';
import { usePageErrorReporting } from '@/hooks/usePageErrorReporting';
import { ErrorProps } from '@/types/error';

export default function ErrorPage(props: ErrorProps) {
  usePageErrorReporting({ ...props, component: 'Home' });

  return <PageError />;
}
