import { H } from '@highlight-run/next/client';
import { useEffect } from 'react';

import { Breadcrumbs, BreadcrumbsConfig } from './navigation/Breadcrumbs';

import { Heading, Text, VStack } from '@/external-components/chakra';

// @todo: temporary not found page
export function PageError({
  breadcrumbsConfig,
  error,
}: {
  breadcrumbsConfig?: BreadcrumbsConfig[];
  error?: Error;
}) {
  useEffect(() => {
    if (!error || !error.message) {
      return;
    }

    H.consumeError(error, error.message, {
      context: 'PageError',
    });
  }, [error]);

  return (
    <>
      {breadcrumbsConfig ? <Breadcrumbs config={breadcrumbsConfig} /> : null}
      <VStack
        sx={{
          alignItems: 'center',
          height: '100%',
          justifyContent: 'center',
          minHeight: '40vh',
        }}
      >
        <Heading size="lg">Oops! Something went wrong</Heading>
        <Text size="md">Please refresh your browser to continue</Text>
      </VStack>
    </>
  );
}
