import { H } from '@highlight-run/next/client';
import { useEffect } from 'react';

import { ErrorProps } from '@/types/error';

export function usePageErrorReporting({
  component,
  error,
}: ErrorProps & { component: string }) {
  useEffect(() => {
    H.consumeError(error, error?.digest, {
      component,
    });
  }, [component, error]);
}
